<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>|
      <a href="https://framework.luoyu.ltd" target="_blank" style="margin-top:30px">落雨快速开发平台</a>
    </div>
    <router-view/>
    <copyrightIcp />
  </div>
</template>
<script>
import copyrightIcp from './components/copyright-icp.vue'
export default {
  components:{copyrightIcp}
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    margin-left: 10px;

    margin-right: 10px;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
