<template>
  <div class="icp">
    <a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a>
    <span class="author">{{ `© ${year} ${author} ` }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(), // 一般都是最新的一年
      author: "落雨",
      record: "桂ICP备2024024326号-3",
    };
  },
};
</script>

<style lang="scss" scoped>
.icp {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px 0;
  width: 100%;
  height: 36px;
  white-space: pre;
  text-align: center;
  color: gray;
  z-index: 1000;

  a {
    color: gray;
    text-decoration: none;
  }
  a:hover {
    color: #42b983;
    text-decoration: none;
  }
  .author{
    margin-left: 10px;
  }
}
</style>
