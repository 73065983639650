<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  metaInfo: {
    title: '落雨随笔',
    meta: [{             // set meta
      name: 'keyWords',
      content: '落雨快速开发平台是基于SpringBoot，Spring Security，JWT，Vue 的前后端分离权限管理系统。'
    },
    {
      name: 'description',
      content: '拥有用户管理、部门管理、岗位管理、菜单管理、角色管理、字典管理、参数管理、通知公告、操作日志、登录日志、在线用户、定时任务、代码生成、系统接口、服务监控、在线构建器、连接池监视。落雨快速开发平台在原有的基础上实现了activit工作流引擎、在线表单...等功能。'
    }],
    link: [{
      rel: 'asstes',
      href: 'http://www.luoyu.ltd'
    }]
  },
  name: 'home',
  components: {
    HelloWorld
  }
}
</script>
